import React from 'react' 
import styled from 'styled-components'

const StyledUnderline = styled.div`
  width: 50px;
  height: 4px;
  background: #2c5282;
  margin-top: 50px;
  margin-bottom: 22px;
`

const PagePrivacySection = () => (  
  <div className='bg-gray-200'>
    <div className='lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8'>
      <h2 className='font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-6'>Interpacific Asset Management Privacy Policy</h2>

      <StyledUnderline></StyledUnderline>

      <div className='w-9/12 sm:w-full'>

        <p className='text-xl text-gray-900 mb-6'>Your privacy is important to us. It is Interpacific Asset Management's policy to respect your privacy regarding any information we may collect from you across our website, www.interpacificmgmt.com, and other sites we own and operate.</p>

        <p className='text-xl text-gray-900 mb-6'>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>

        <p className='text-xl text-gray-900 mb-6'>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>

        <p className='text-xl text-gray-900 mb-6'>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>

        <p className='text-xl text-gray-900 mb-6'>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>

        <p className='text-xl text-gray-900 mb-6'>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>

        <p className='text-xl text-gray-900 mb-6'>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>

        <p className='text-xl text-gray-900 mb-6'>This policy is effective as of 20 December 2019.</p>
      </div>
    </div>
  </div>      
)

export default PagePrivacySection